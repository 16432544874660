import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";
const BASE_PRINT_CATEGORY_URL = BASE_API_URL+"print_categories"


@Component
export default class PrintCategoriesMixin extends Vue {
  getPrintCategories(data: any, tag: string) {
    return this.$httpGetQuery(
      BASE_PRINT_CATEGORY_URL,
      tag,
      data
    );
  }

  getPrintCategory(id: number, tag: string) {
    return this.$httpGet(
      BASE_PRINT_CATEGORY_URL+"/"+id,
      tag
    );
  }

  getPrintableOrders(id: any, tag: string) {
    return this.$httpGet(
      BASE_PRINT_CATEGORY_URL+"/"+id+"/printable_orders",
      tag
    );
  }

  destroyPrintCategory(id: string|number, tag: string) {
    return this.$httpDelete(BASE_PRINT_CATEGORY_URL+"/"+id, tag);
  }

  createPrintCategory(data: any, tag: string) {
    return this.$httpPost(BASE_PRINT_CATEGORY_URL, tag, data);
  }

  updatePrintCategory(id: string, data: any, tag: string) {
    return this.$httpPut(
      BASE_PRINT_CATEGORY_URL+"/"+id,
      tag,
      data
    );
  }
}

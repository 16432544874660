import { Subscription } from "@rails/actioncable";
import store from "@/store";

class PrintCycleAdapter {
  received: (data: any) => void;

  constructor(onResponse: (data: any) => void) {
    this.received = onResponse;
  }
}

export default class PrintCable {
  subscription: Subscription & PrintCycleAdapter | null = null;

  onResponse!: (data: any) => void;

  close() {
    store.commit('disconnect', {root: true});
  }

  async open() {
    const params = {
      channel: "PrintJobChannel",
    };
    const handler = new PrintCycleAdapter(this.onResponse);

    this.subscription = await store.dispatch('subscribe', {params, handler});
  }
}




















import { Order } from '@/models';
import DataTable from '@monade/vue-components/src/components/DataTable.vue';
import RoundThumb from '@/components/RoundThumb.vue';
import ClickOutside from '@/directives/ClickOutside';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Design } from '@/models';
import { OrderLine } from '@/models';
const previewCount = 5;

@Component({
  components: {
    RoundThumb,
    DataTable
  },
  directives: { ClickOutside }
})
export default class Preview extends Vue {
  @Prop() order: Order;

  open = false;

  columns = [
    {
      name: 'image',
      label: ''
    },
    {
      name: 'name',
      label: 'Nome'
    },
    {
      name: 'quantity',
      label: 'Quantità'
    },
    {
      name: 'total',
      label: 'Prezzo'
    }
  ];

  urls(order: Order) {
    return order.orderLines
      .filter((o: OrderLine) => o.designs !== null)
      .map((o: OrderLine) => o.designs.map((o: Design) => o.url));
  }

  url(designs: Design[]) {
    return designs ? designs[0]?.url : '';
  }

  others(order: Order) {
    return this.urls(order)?.length - previewCount;
  }

  orderPreviewUrls(order: Order) {
    const urls = order.orderLines
      .filter((o: OrderLine) => o.designs !== null)
      .map((o: OrderLine) => o.designs.map((o: Design) => o.url))
      .slice(0, previewCount);
    return urls.flat();
  }
}





















import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { Vue, Component} from "vue-property-decorator";

@Component({ components: { VPageContentSticky } })
export default class PrintDashboard extends Vue {}

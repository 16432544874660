









































































import { Source } from "@/models";
import {  Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PrintGroupsMixin from "@/mixins/http/PrintGroupsMixin";
import PrintsMixin from "@/mixins/http/PrintsMixin";
import PrintCategoriesMixin from "@/mixins/http/PrintCategoriesMixin";
import PrintCategoryMultiSelect from "@/components/PrintCategory/MultiSelect.vue";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import CreatePrint from "@/components/Print/CreateItem.vue";
import PrintCard from "@/components/Print/CardItem.vue";
import ModelParserMixin from "@/mixins/ModelParser";
import { showToast } from "@/utils/Toast";
import { CreatePrintGroupPayload } from "@/models/PrintGroup";
import PrintCategorySelect from "@/components/PrintCategory/Select.vue";
import PageSection from "@/components/PageSection.vue";
import { PrintGroup, PrintCategory } from "@/models";
import { CreatePrintPayload } from "@/models/PrintGroup";
import { serialize } from "object-to-formdata";
import PrintCable from "@/cables/printCable";
import { easync } from "@/utils/http";
import JobToast from '@/components/JobToast.vue';
import { sourceStore } from "@/store/typed";
import Throttler from "@/utils/Throttler";

const createItemTag = "create_print";
const getOrdersTag = "get_printable_orders";

@Component({
  components: {
    PrintCategorySelect,
    CreatePrint,
    PrintCard,
    PageSection,
    PrintCategoryMultiSelect,
    JobToast
  },
})
export default class PrintGroupCreate extends Mixins(
  ModelParserMixin,
  PrintCategoriesMixin,
  PrintGroupsMixin,
  PrintsMixin
) {
  @RegisterHttp(createItemTag) createItemRequest: RequestData;
  @RegisterHttp(getOrdersTag) getOrdersRequest: RequestData;

  payload: CreatePrintGroupPayload = new CreatePrintGroupPayload();
  printGroup: PrintGroup = null;
  private printCategories: PrintCategory[] = [];
  private synchronizer: PrintCable | null = null;
  private readonly throttler: Throttler = new Throttler();

  printCategory: PrintCategory = null;

  private jobProgress = 0;

  get canPrint() {
    return !this.printGroup?.prints?.length
  }

  get source(){
    return sourceStore.currentSource
  }

  mounted() {
    this.payload.sourceId = this.source?.id;
    this.initSynchronizer();
  }

  initSynchronizer() {
    if (this.synchronizer) {
      this.synchronizer.close();
    }
    this.synchronizer = new PrintCable();
    this.synchronizer.onResponse = this.onImporterResponse;
    this.synchronizer.open();
  }

  onImporterResponse(data: any) {
    console.log('data from channel', data);
    this.jobProgress = data.progress as number;
  }

  get readyToBePrinted() {
    return (
      this.payload.prints.length > 0 &&
      this.payload.prints.every((o) => o.ready)
    );
  }

  onPrintCategoriesLoaded(payload: PrintCategory[]) {
    this.printCategories = payload;
  }

  async onPrintCategorySelected(payload: { printCategoryIds: string[] }) {

    this.payload.prints = [];

    this.throttler.run(async () => {

      const printCategoryIds =  payload.printCategoryIds.filter((x, i, a) => a.indexOf(x) === i)
      for (const id of printCategoryIds) {
        if (!this.payload.prints.map((o) => o.printCategoryId).includes(id)){
          const printPayload = new CreatePrintPayload();
          const resp = await this.getPrintableOrders(id, getOrdersTag);
          printPayload.printCategoryId = id;
          printPayload.orders.push(...resp.ids);
          printPayload.ordersToPrint.push(...resp.ids);
          this.payload.prints.push(printPayload);
        }
      }
    })

  }

  async performCreate() {
    const [res, errors] = await easync(
      this.createPrintGroup(serialize(this.payload), createItemTag)
    );

    if (errors) {
      showToast("Errore nella creazione delle stampe", { type: "error" });
      return;
    }

    this.printGroup = res;
    showToast("Stampe inizializzate!");
  }

  get prints() {
    return this.printGroup?.prints;
  }

  destroyed() {
    this.synchronizer.close();
  }

  get loading() {
    return this.createItemRequest?.loading;
  }
}































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import VIcon from '@/components/VIcon.vue';

@Component({
  components: {
    VIcon
  }
})
export default class JobToast extends Vue {
  @Prop({ default: 0 }) readonly progress!: number;
  @Prop({ default: '' }) readonly jobName!: string;

  private open = false;

  @Watch('progress')
  onProgressChanged() {
    this.open = this.progress ? true : false;
  }

  get jobProgress() {
    return this.progress;
  }

  get style() {
    return `width: ${this.jobProgress}%`;
  }

  get show() {
    return this.jobProgress && this.open;
  }

  close() {
    this.open = false;
  }
}


















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class PageSection extends Vue {
  @Prop() title?: string;
  @Prop({ default: 5 }) leftColumns?: number;
  @Prop({ default: 7 }) rightColumns?: number;

  get leftClass() {
    return `col-12 col-md-${this.leftColumns}`;
  }
  get rightClass() {
    return `col-12 col-md-${this.rightColumns}`;
  }
}

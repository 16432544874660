





































































































































































import { PrintCategory, PrintPreview } from "@/models";
import PrintsMixin from "@/mixins/http/PrintsMixin";
import { CreatePrintPayload } from "@/models/PrintGroup";
import OrderMultiSelect from "@/components/Order/MultiSelect.vue";
import ProductMultiSelect from "@/components/Product/MultiSelect.vue";
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import Preview from "@/components/Print/Preview.vue";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { PREVIEW_TAG } from "@/mixins/http/PrintsMixin";
import Throttler from "@/utils/Throttler";
import FileUploader from "@/components/FileUploader.vue";
import { CutFile } from "@/models";
import VSelect from "@/components/VSelect.vue";
import SideSelect from "@/components/PrintSide/Select.vue";
import CutFilesMixin, { CUT_FILES_INDEX } from "@/mixins/http/CutFilesMixin";
import { sourceStore } from "@/store/typed";

@Component({
  components: {
    OrderMultiSelect,
    ProductMultiSelect,
    Preview,
    SideSelect,
    FileUploader,
    VSelect,
  },
})
export default class PrintCreateItem extends Mixins(
  PrintsMixin,
  CutFilesMixin
) {
  @Prop({ required: true }) readonly print!: CreatePrintPayload;

  @RegisterHttp(PREVIEW_TAG) previewRequest: RequestData;
  @RegisterHttp(CUT_FILES_INDEX) getCutFilesRequest: RequestData;

  @Prop({ required: true }) readonly printCategories!: PrintCategory[];

  private printReview: PrintPreview = null;

  private images: any[] = [];

  private cutFiles: CutFile[] = [];

  private includeNonPrintedOrders = true;

  private item: CreatePrintPayload = this.print;

  private open = false;

  private throttler = new Throttler();

  @Watch("includeNonPrintedOrders")
  onInclusionChanged(value: any) {
    if (value) {
      this.item.orders = this.item.ordersToPrint;
    } else {
      this.item.orders = this.item.orders.filter(
        (el) => !this.item.ordersToPrint.includes(el)
      );
    }
    this.getPreview();
    this.emit();
  }

  get defaultCutFileValue() {
    return this.options?.[0].id;
  }

  get source() {
    return sourceStore.currentSource;
  }

  async loadCutFiles() {
    const data = { source: this.source?.id };
    this.cutFiles = await this.getCutFiles(data);
  }

  onImageAdded(files: any[]) {
    for (const file of files) {
      const image = this.print.images.find((o) => o.name === file.name);
      if (!image) {
        this.print.images.push({
          quantity: 1,
          id: file.id,
          file: file.file,
          url: file.url,
          name: file.name,
        });
      }
    }

    this.getPreview();
    this.emit();
  }

  get sidesCount() {
    return this.printCategory?.printSides?.length == 1
      ? `${this.printCategory?.printSides?.length} lato`
      : `${this.printCategory?.printSides?.length} lati`;
  }

  get printCategoryName() {
    return this.printCategory?.name;
  }

  get printCategory() {
    return this.printCategories.find(
      (o) => o.id === this.print.printCategoryId
    );
  }

  get options() {
    return this.cutFiles.map((o) => {
      return { id: o.id, text: o.name };
    });
  }

  changeQuantity(quantity: number) {
    this.throttler.run(() => {
      this.getPreview();
      this.emit();
    });
  }

  async mounted() {
    await this.loadCutFiles();
    await this.getPreview();
  }

  onOrdersSelected(payload: { orderIds: string[] }) {
    if (this.includeNonPrintedOrders) {
      this.print.orders = [...payload.orderIds, ...this.print.ordersToPrint];
    } else {
      this.print.orders = payload.orderIds;
    }
    this.getPreview();
    this.emit();
  }

  async getPreview() {
    this.printReview = await this.printPreview(this.print);
  }

  onProductsSelected(payload: { productIds: string[] }) {
    const productItems = payload.productIds.map((o) => {
      return { quantity: 1, id: o.split("/")[0], text: o.split("/")[1] };
    });
    this.print.products = productItems;
    this.getPreview();
    this.emit();
  }

  removeImage(index: number) {
    this.print.images.splice(index, 1);
    this.getPreview();
    this.emit();
  }

  removeProduct(index: number) {
    this.print.products.splice(index, 1);
    this.getPreview();
    this.emit();
  }

  emit() {
    this.$emit("changed", this.print);
  }
}

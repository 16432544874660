


















import { PrintGroup } from "@/models";
import PrintCard from "@/components/Print/CardItem.vue";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ components: { PrintCard } })
export default class PrintGroupListItem extends Vue {
  @Prop() printGroup: PrintGroup;

  get prints() {
    return this.printGroup.prints;
  }

  onDelete() {

    this.$emit('deleted', this.printGroup.id)
  }
}

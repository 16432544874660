






































import { Print } from "@/models";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class PrintCardItem extends Vue {
  @Prop({ required: true }) print!: Print;

  get status() {
    return this.print.formattedStatus;
  }

  get badgeStyle() {
    return `badge-${this.print.statusStyle}`;
  }

  get title() {
    return this.print?.name || this.print?.printCategory?.name || "Etichette";
  }

  get outputsLength() {
    return this.print.outputsCount
  }

  onClick(item: Print) {
    this.$router.push({ name: "prints_edit", params: { id: item.id } });
  }
}

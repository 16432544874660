































































import { NoCache } from "@/utils/Decorators";
import { PrintCategory, PrintPreview } from "@/models";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";

@Component({})
export default class Preview extends Vue {
  @Ref("output") readonly output: any;

  @Prop({ required: true }) readonly printCategory: PrintCategory;

  @Prop({ required: true }) readonly preview: PrintPreview;

  private clientWidth = 0;

  mounted() {
    this.clientWidth = this.output.clientWidth;
  }

  get completedOutputs() {
    if (!this.preview?.filesToComplete) {
      return this.preview?.outputsCount;
    } else {
      return (this.preview?.outputsCount || 0) - 1;
    }
  }

  @NoCache
  get ratio() {
    return this.outputWidth / this.printCategory.width;
  }

  get completedRows() {
    return this.preview.filesCount ? Math.floor(this.lastOutputFiles / this.printCategory.columns) : 0;
  }

  get lastOutputLastRowFiles() {
    return (this.itemsPerOutput - this.preview.filesToComplete)%this.printCategory.columns;
  }

  get lastOutputFiles() {
    return this.preview.filesCount ? this.itemsPerOutput - this.preview.filesToComplete : 0;
  }

  @NoCache
  get outputHeight() {
    return this.printCategory.height * this.ratio;
  }

  @NoCache
  get outputWidth() {
    return this.output?.clientWidth || 0;
  }

  @NoCache
  get outputStyle() {
    return { height: `${this.outputHeight}px` };
  }

  get itemsPerOutput() {
    return this.printCategory.rows * this.printCategory.columns;
  }

  @NoCache
  get cellStyle() {
    return {
      width: `${Math.floor(this.outputWidth / this.printCategory.columns)}px`,
      height: `${Math.floor(this.outputHeight / this.printCategory.rows)}px`,
    };
  }
}

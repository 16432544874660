import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";
const BASE_PRINT_GROUP_URL = BASE_API_URL+"print_groups"


@Component
export default class PrintGroupsMixin extends Vue {
  getPrintGroups(data: any, tag: string) {
    return this.$httpGetQuery(
      BASE_PRINT_GROUP_URL,
      tag,
      data
    );
  }

  getPrintGroup(id: number, tag: string) {
    return this.$httpGet(
      BASE_PRINT_GROUP_URL+"/"+id,
      tag
    );
  }

  destroyPrintGroup(id: string|number, tag: string) {
    return this.$httpDelete( BASE_PRINT_GROUP_URL+"/"+id, tag);
  }

  createPrintGroup(data: any, tag: string) {
    return this.$httpPost(BASE_PRINT_GROUP_URL, tag, data);
  }
}

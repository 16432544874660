















































import { Order, Source } from "@/models";
import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { Getter, Action, namespace } from "vuex-class";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import Preview from "@/components/Order/Preview.vue";
import PrintGroupItem from "@/components/PrintGroup/ListItem.vue";
import PrintGroupsMixin from "@/mixins/http/PrintGroupsMixin";
import { Print } from "@/models";
import { PrintGroup } from "@/models";
import ModelParserMixin from "@/mixins/ModelParser";
import { showToast } from "@/utils/Toast";
import PrintCable from "@/cables/printCable";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";

const getPrints = "get_prints";
const deleteTag = "delete_print_group";

@Component({ components: { DataTable, RoundThumb, Preview, PrintGroupItem } })
export default class PrintGroupList extends Mixins(PrintGroupsMixin,ModelParserMixin) {
  @RegisterHttp(getPrints) getPrintsRequest: RequestData;
  @RegisterHttp(deleteTag) deleteTagRequest: RequestData;

  private synchronizer: PrintCable | null = null;

  items: PrintGroup[] = [];
  page = 1;

  created() {
    this.subscribe();
  }

  onSelected(item: Print) {
    this.$router.push({ name: "prints_show", params: { id: item.id } });
  }

  onDeleted(id: string) {
    this.destroyGroup(id);
  }

  initSynchronizer() {
    if (this.synchronizer) {
      this.synchronizer.close();
    }
    this.synchronizer = new PrintCable();
    this.synchronizer.onResponse = this.onImporterResponse;
    this.synchronizer.open();
  }

  onImporterResponse(data: any) {

    if(!data.data || !this.items) {
      return;
    }

    const print = this.$parseModel(data);
    for(let group of this.items) {
      let printToUpdate = group.prints.find(o => o.id === print.id);
      if(!printToUpdate) {
        continue;
      }
      const printCategory = printToUpdate.printCategory;
      printToUpdate = Object.assign(printToUpdate, print,{printCategory: printCategory} );
    }
  }


  async destroyGroup(id: string) {
      try{
      await this.destroyPrintGroup(id, deleteTag);
              showToast('Fatto!');
              this.items = this.items.filter(o => id !== o.id);

      }catch(e) {
        showToast('Non è stato possibile cancellare il gruppo di stampa', {type: 'error'});
      }
    }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  mounted() {
    this.loadItems();
    //this.initSynchronizer();
  }

  destroyed() {
    this.synchronizer.close();
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  async loadItems() {
    if (this.getPrintsRequest?.loading) {
      return;
    }
    const data = {
      per: 20,
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id
      }
    };

    const [res, errors] = await easync(this.getPrintGroups(data, getPrints));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...res);
  }

  get loading() {
    return this.getPrintsRequest?.loading;
  }

  get meta() {
    return this.getPrintsRequest?.meta;
  }
}

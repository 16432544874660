
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

export const CUT_FILES_INDEX = 'CUT_FILES_INDEX';
export const CUT_FILES_CREATE = 'CUT_FILES_CREATE';
export const CUT_FILES_DESTROY = 'CUT_FILES_DESTROY';
export const CUT_FILES_UPDATE = 'CUT_FILES_UPDATE';

const BASE_CUT_FILE_URL = BASE_API_URL+"cut_files"

@Component
export default class CutFilesMixin extends Vue {

  getCutFiles(data: any, tag = CUT_FILES_INDEX) {
    return this.$httpGetQuery(BASE_CUT_FILE_URL, tag, data);
  }

  destroyCutFile(id: number|string, tag = CUT_FILES_DESTROY) {
    return this.$httpDelete(BASE_CUT_FILE_URL+"/"+id, tag);
  }

  createCutFile(data: any, tag = CUT_FILES_CREATE) {
    return this.$httpPost(BASE_CUT_FILE_URL, tag, data);
  }

  updateCutFile(id: string, data: any, tag = CUT_FILES_UPDATE) {
    return this.$httpPut(BASE_CUT_FILE_URL+"/"+id, tag, data);
  }
}



























































































import { Source } from "@/models";
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import Preview from "@/components/Print/Preview.vue";
import PrintsMixin from "@/mixins/http/PrintsMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import VCheckbox from "@/components/VCheckbox.vue";
import PrintCategorySelect from "@/components/PrintCategory/Select.vue";
import PageSection from "@/components/PageSection.vue";
import { Print } from "@/models";
import PrintCategoriesMixin from "@/mixins/http/PrintCategoriesMixin";
import FilesCalculator from "@/components/Print/FilesCalculator.vue";
import { PrintPreview } from "@/models";
import { Order } from "../../models";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";

const getItemTag = "get_print";
const getOrdersTag = "get_printable_orders";
const downloadTag = "download_tag";

@Component({
  components: {
    Preview,
    VPageContentSticky,
    PrintCategorySelect,
    PageSection,
    FilesCalculator,
    VCheckbox,
  },
})
export default class PrintEdit extends Mixins(
  PrintsMixin,
  PrintCategoriesMixin
) {
  @RegisterHttp(getItemTag) getItemRequest: RequestData;
  @RegisterHttp(getOrdersTag) getOrdersRequest: RequestData;
  @RegisterHttp(downloadTag) downloadRequest: RequestData;

  print: Print = null;
  private orders: { ids: string[]; quantity: number } = {
    ids: [],
    quantity: 0,
  };
  private printNotPrintedYet = true;

  get preview() {
    const preview = PrintPreview.fromPrint(this.print);

    return preview;
  }

  mounted() {
    this.load();
  }

  get id() {
    return this.$route.params.id;
  }

  get name() {
    return this.print?.name;
  }
  get statusClass() {
    return this.print ? `badge-print-status-${this.print?.status}` : "";
  }

  get isIdle() {
    return this.print?.status === "idle";
  }

  get isCompleted() {
    return this.print?.status === "completed";
  }

  get isError() {
    return this.print?.status === "error";
  }

  async downloadAttachments() {
    const [data, errors] = await easync(
      this.downloadPrints(this.print.id, downloadTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.forceFileDownload(data);
  }

  forceFileDownload(response: any) {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "output_" + this.print.id + "_prints.zip");
    document.body.appendChild(link);
    link.click();
  }

  openDocument(link: string) {
    window.open(link, "_blank");
  }

  get calculatorData() {
    return { orders: this.orders };
  }

  async load() {
    const [data, errors] = await easync(
      this.getPrint(parseInt(this.id), getItemTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.print = data;
    this.loadPrintableOrders();
  }

  get downloading() {
    return this.downloadRequest?.loading;
  }

  async loadPrintableOrders() {
    const [res, errors] = await easync(
      this.getPrintableOrders(this.print.printCategory.id, getOrdersTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.orders = res;
  }

  get loading() {
    return this.getItemRequest?.loading;
  }
}





































import { PrintCategory } from "@/models";
import { Vue, Component, Prop } from "vue-property-decorator";

export interface FilesCalculatorParams {
  orders: { ids: string[]; quantity: number };
}

@Component({})
export default class PrintFilesCalculator extends Vue {
  @Prop({ required: true }) readonly printCategory!: PrintCategory;
  @Prop({ required: true }) readonly items!: FilesCalculatorParams;

  get orders() {
    let quantity = this.items.orders.quantity;
    if (this.printCategory.printQrCode) {
      quantity += this.items.orders.ids.length;
    }
    return quantity;
  }

  get filesPerDocument() {
    return this.printCategory.columns * this.printCategory.rows;
  }

  get products() {
    return 0;
  }

  get files() {
    return 0;
  }

  get filesToCompleteDocument() {
    return this.filesPerDocument - (this.totalFiles % this.filesPerDocument);
  }

  get totalFiles() {
    return this.orders + this.files + this.products;
  }

  get totalDocuments() {
    return Math.ceil(this.totalFiles / this.filesPerDocument);
  }
}

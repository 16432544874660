import { httpStore } from "@/store/typed";
import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";

export const PREVIEW_TAG = 'print_preview';
const BASE_PRINT_URL = BASE_API_URL+"prints"

@Component
export default class PrintsMixin extends Vue {
  getPrints(data: any, tag: string) {
    return this.$httpGetQuery(
      BASE_PRINT_URL,
      tag,
      data
    );
  }

  getPrint(id: number, tag: string) {
    return this.$httpGet(
      BASE_PRINT_URL+"/"+id,
      tag
    );
  }

  printPreview(data: any) {
    return this.$httpPost(BASE_PRINT_URL+"/preview", PREVIEW_TAG, data);
  }

  downloadPrints(id: number, tag: string) {
    // return this.$httpGet(
    //   BASE_PRINT_URL+"/"+id+"/download_outputs",
    //   tag
    // );
    const url = BASE_PRINT_URL+"/"+id+"/download_outputs";
    return httpStore.request({
      url: url,
      tag: tag,
      method: 'GET',
      responseType: 'blob',
      noParse: true
    });
  }

  destroyPrint(id: string|number, tag: string) {
    return this.$httpDelete(BASE_PRINT_URL+"/"+id, tag);
  }

  createPrint(data: any, tag: string) {
    return this.$httpPost(BASE_PRINT_URL, tag, data);
  }

  updatePrint(data: any, tag: string) {
    return this.$httpPut(
      BASE_PRINT_URL+"/"+data.id,
      tag,
      data
    );
  }
}
